import { SELECTED_PARTNER_ID_COOKIE_EXPIRATION_DAYS } from './../../../hapi-plugins/auth/utils';
const authorizeUrl = '/eg/auth/custom/authorize';
const getPartnerAccountId = authUrl => {
  const partnerSearchParam = 'assumed_partner_account_id=';
  return authUrl.substring(authUrl.indexOf(partnerSearchParam) + partnerSearchParam.length);
};
const setPartnerAccountIdCookie = partnerAccountId => {
  const hostName = window.location.hostname;
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + SELECTED_PARTNER_ID_COOKIE_EXPIRATION_DAYS);
  document.cookie = `SELECTED_PARTNER_ACCOUNT_ID=${partnerAccountId}; secure=true; path=/; sameSite=None; domain=.${hostName}; expires=${expirationDate}`;
};
const prepareAuthorizationURL = (partnerId, pathName) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append('redirect_uri', sanitizeAuthRedirectURI(pathName));
  urlSearchParams.append('assumed_partner_account_id', partnerId);
  return `${authorizeUrl}?${urlSearchParams.toString()}`;
};
const sanitizeAuthRedirectURI = pathName => {
  if (pathName === '/') return pathName;
  return pathName.endsWith('/') ? pathName.substring(0, pathName.length - 1) : pathName;
};
export { getPartnerAccountId, setPartnerAccountIdCookie, prepareAuthorizationURL, sanitizeAuthRedirectURI };
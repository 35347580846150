import * as React from 'react';
import HomeContent from './home-content/home-content';
import { CookieBanner } from '../components/cookie-banner/cookie-banner';
// import { NoAccountDialog } from '../components/dialog/no-account-dialog';
import { HelmetProvider } from 'react-helmet-async';
// import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
// keep it at the app entry level to avoid memory leaks.
// reference: https://github.com/staylor/react-helmet-async#usage
// reference: https://github.com/nfl/react-helmet#server-usage
const helmetContext = {};
export const Home = () => {
  return React.createElement(HelmetProvider, {
    context: helmetContext
  }, React.createElement(CookieBanner, null), React.createElement(HomeContent, null));
};
Home.displayName = 'Home';
export default Home;
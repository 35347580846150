import React, { useState } from 'react';
import { UitkMenuList } from 'uitk-react-menu';
import { useHistory } from 'react-router-dom';
import { Overlay } from './../../common/overlay';
import { PartnerSwitchDialog } from './../../dialog/partner-switch-dialog';
import { getPartnerAccountId, prepareAuthorizationURL, setPartnerAccountIdCookie } from './utils';
export const AuthenticatedUserSubMenu = ({
  subMenu
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const switchPartnerAccount = authUrl => {
    setIsLoading(true);
    history.push({
      search: '?dialog=partner-switch'
    });
    const partnerId = getPartnerAccountId(authUrl);
    window.location.href = prepareAuthorizationURL(partnerId, location.pathname);
    setPartnerAccountIdCookie(partnerId);
  };
  const getSubMenuItems = () => {
    return subMenu.items.map(item => {
      if (item.__typename === 'DocumentationHeaderMenuLinkItem') return {
        label: item.title,
        className: 'uitk-list-item',
        onClick: () => switchPartnerAccount(item.action.resource.value)
      };
    });
  };
  return React.createElement(React.Fragment, null, React.createElement(UitkMenuList, {
    items: getSubMenuItems(),
    title: subMenu === null || subMenu === void 0 ? void 0 : subMenu.title
  }), isLoading && React.createElement(Overlay, null), React.createElement(PartnerSwitchDialog, null));
};
import React from 'react';
import HeaderTitle from '../header-title';
import TabContextProvider from './../../contexts/tab-context';
import Chatbot from '../chatbot/chatbot';
const HomePageMobileLayout = ({
  header,
  sidebar,
  document
}) => React.createElement(TabContextProvider, null, React.createElement("div", {
  id: "home-page-layout-mobile"
}, React.createElement("header", {
  id: "page-header-mobile"
}, header), React.createElement(HeaderTitle, null), React.createElement("main", {
  id: "page-body-mobile"
}, React.createElement("section", {
  id: "sidebar-mobile"
}, sidebar), React.createElement("section", {
  id: "document-mobile"
}, document)), React.createElement(Chatbot, null)));
export default HomePageMobileLayout;
import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { CopyAction } from '../../copy-action/copy-action';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
export const PartnerAccountDetails = ({
  subMenu
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j;
  const currentPartnerName = (_c = (_b = (_a = subMenu.items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : '';
  const currentPartnerType = (_f = (_e = (_d = subMenu.items) === null || _d === void 0 ? void 0 : _d[1]) === null || _e === void 0 ? void 0 : _e.title) !== null && _f !== void 0 ? _f : '';
  const currentPartnerId = (_j = (_h = (_g = subMenu.items) === null || _g === void 0 ? void 0 : _g[2]) === null || _h === void 0 ? void 0 : _h.title) !== null && _j !== void 0 ? _j : '';
  return React.createElement(UitkSpacing, {
    padding: {
      inlinestart: 'six',
      inlineend: 'one'
    }
  }, React.createElement("div", null, React.createElement(UitkHeading, {
    tag: "h2",
    size: 5
  }, currentPartnerName), React.createElement(UitkText, {
    theme: "default",
    spacing: "two",
    weight: "medium"
  }, currentPartnerType), currentPartnerId ? React.createElement(UitkLayoutFlex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    theme: "default",
    spacing: "two"
  }, currentPartnerId)), React.createElement(UitkLayoutFlexItem, null, React.createElement(CopyAction, {
    copyValue: currentPartnerId.split(': ')[1]
  }))) : React.createElement(React.Fragment, null)));
};